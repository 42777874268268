<script>
    export let visible = true;
    export let message = "error";
    export let togglevisible = () => {};
</script>

{#if visible == true}
    <div class="modal">
        <div class="modalbg">
            <!--<button class="closemodal" on:click={() => {togglevisible(); visible = false}}>&#9932</button>-->
            <button class="ok-button" style="" on:click={() => {togglevisible(); visible = false}}>OK</button>
            <p>{message}</p>
        </div>
    </div>
{/if}
<style>

.modal{
        z-index: 20;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
       
    }
    .ok-button {
        background-color: #460000;
        font-weight: bold;
        color: white;
        border-radius: 10px;
        grid-row: 2;
        grid-column: 1;
        padding: 1rem;
        margin-top: 4rem;
        width: 100%;
    }
    .modalbg{
        position: fixed;
        
        width: 100%;
        padding: 1rem;
        background: #444444;
        border-radius: 1rem;
        
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        justify-items: center;
        align-items: center;
    }

    @media (min-width: 800px){
        .modalbg{
            width: 800px
        }
        .ok-button{
            width: 10rem;
        }
    }
    
    .modalbg p{
        color: white;
        grid-row: 1;
        grid-column: 1;
    }

    .closemodal{
        
        width: 2rem;
        height: 2rem;
        background: none;
        border: none;
        color: white;
        border-radius: 1rem;
        grid-row: 1;
        grid-column: 1;
    }

</style>
<script>
    import Booking from "./Booking.svelte";
    export let tableamount = 2;
    export let bookings;
    export let timeslots;
    export let dayofmonth = 1;
    export let dayofweek = 1;
    export let server;

    let currentpositionstart;
    let currentpositionend;
    function getgridpositionstart(book){
        
        for(let i = 0;i < timeslots.length;i++){
            if(book.start == timeslots[i][0]){
                currentpositionstart = i + 1;
                return currentpositionstart;
            }
        }
    };

    function getgridpositionend(book){
        
        for(let i = 0;i < timeslots.length;i++){
            if(book.end == timeslots[i][0]){
                currentpositionend = (i + 2) - currentpositionstart;
                return currentpositionend;
            }
        }
    };

    function formatTime(timeString) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }
</script>

<div class="main" style="--rowcount:{timeslots.length}; --colcount:{tableamount + 2};">
    {#each timeslots as time, i}
        <p id="{formatTime(time[0])}" style="--gridpos:{i + 2}" class="time">{time[1]}</p>
    {/each}
    {#each Array(tableamount) as _,i}
        <div class="column" style="--col:{i+2};--height:{timeslots.length};">
            <h3 class="title">Table {i + 1}</h3>
        </div>
    {/each}

    {#if bookings.length > 0}
        {#each bookings as book}
            {#if book.monthday == dayofmonth}
                <Booking server={server} booking={book} column={book.table + 1} gridstart={getgridpositionstart(book) + 1} gridspan={getgridpositionend(book) }/>
               
            {/if}
        {/each}
    {/if}
</div>

<style>
    .title{
        color:white;
        text-align: center;
        vertical-align: middle;
    }
    .main{
       
        display: grid;
        grid-template-columns:repeat(var(--colcount),10rem) ;
        grid-template-rows: repeat(var(--rowcount),2.5rem);
        row-gap: 0.5rem;
        justify-items: center;
        
    }
    .column{
        justify-items: center;
        min-width: 10rem;
        grid-row-start: 1;
        grid-row-end: 10;
        grid-column-start: var(--col);
        grid-column-end: var(--col);
        position: sticky;
        top: 0;
        bottom: 0;

    }
    .column::after{
        width: 1px;
        background: linear-gradient(rgb(100,100,100,1),rgb(100,100,100,0));
        
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: "";
    }

    .time{
        color: #b6b6b6;
        font-weight: bold;
        grid-row-start: var(--gridpos);
        grid-row-end: var(--gridpos);
        justify-content: center;
        margin: 0;
        text-align: center;
        height: 2.5rem;
        line-height: 2.5rem;
    }

</style>
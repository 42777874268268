<script>
    import { onMount } from "svelte";

    export let starttimes = [["10:00 am", "10:00"],["11:00 am", "11:00"]];
    export let oninputchange = () => {}
    let chosenstarttime = starttimes[0][0];

    function GetEndTimes(starttimechosen){
        let endtimes = starttimes;
        let newendtimes = []; //the returned array

        let startdate = new Date(); //create start time date from select input to compare
        const stime = starttimechosen.split(":");
        startdate.setHours(Number(stime[0]));
        startdate.setMinutes(Number(stime[1]));
        startdate.setSeconds(0);
        startdate.setMilliseconds(0);//---

        let testenddate = new Date(startdate.getTime());
        let lasttestenddate = new Date(testenddate.getTime());
        let is1hourapart = false;
        let doesoverlap = false;
        for(let i = 0; i < endtimes.length; i++){
            is1hourapart = false;
            doesoverlap = false;
            let etime = endtimes[i][0].split(":");
            //console.log("etime: " + etime.toString());
            testenddate.setHours(Number(etime[0]));
            testenddate.setMinutes(Number(etime[1]));
            testenddate.setSeconds(0);
            testenddate.setMilliseconds(0);
            if(GetMinutesBetween(testenddate, startdate) >= 60){
                is1hourapart = true;
            }
            if(GetMinutesBetween(testenddate, lasttestenddate) > 30){
                doesoverlap = true;
            }else{
                lasttestenddate = new Date(testenddate.getTime());
            }

            if(is1hourapart == true){
                if(doesoverlap == false){
                    newendtimes.push(endtimes[i]);
                }
            }


        }

        return newendtimes;
    }

    function GetMinutesBetween(Date1, Date2){
        let diff = Date1 - Date2;
        diff = Math.abs(diff);
        diff = Math.round(diff / 60000);
        console.log("Minutes between: " + Date1.toString() + " and " + Date2.toString() + " = " + diff.toString());
        return diff;
    }

    function query(id){
      let el = document.querySelector("#" + id);
      return el;
    }
    onMount(() => {oninputchange(query("endtime"));oninputchange(query("starttime"));})
</script>

<div class="flex flex-row py-2 ">
    <div class="w-full mr-2">
        <label for="starttime" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start Time</label>
        <select on:change={oninputchange(query("starttime"))} bind:value={chosenstarttime} id="starttime" class="border text-white border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
            {#each starttimes as time}
                <option value="{time[0]}">{time[1]}</option>
            {/each}
        </select>
        
    </div>
    <div class="w-full ml-2">
        <label for="starttime" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End Time</label>
        <select on:change={oninputchange(query("endtime"))} id="endtime" class="border text-white border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ">
            {#each GetEndTimes(chosenstarttime) as time}
                <option value="{time[0]}">{time[1]}</option>
            {/each}
      </select>
      
    </div>
</div>

<style>
    input:focus, button:focus, select:focus{
        outline: solid 0.1rem silver;
    }
</style>
<script>
    import { query_selector_all } from "svelte/internal";

   

    export let inputs = [{"type": "email", "label" : "Your email", "id" : "email", "default": "", "placeholder": "name@email.com"}];
    export let values = [];
    export let HasSubmit = true;
    export let id;
    export let oninputchange = () => {}
    export let onlosefocus = () => {}

    let changedvalue;

    function query(id){
      let el = document.querySelector("#" + id);
      return el;
    }

    let phoneNumber = '';
    let count;

    function formatPhoneNumber() {
      // Remove all non-numeric characters from the phone number
      let cleaned = phoneNumber.replace(/\D/g, '');

      // Format the phone number with dashes
      let formatted = '';
      for (let i = 0; i < cleaned.length; i++) {
        if (i === 2) {
          formatted += cleaned[i] + '-';
        } else if (i === 5) {
          formatted += cleaned[i] + '-';
        } else {
          formatted += cleaned[i];
        }
      }
      if(formatted.length > 12){
        formatted = formatted.substring(0, 12);
      }

      // Update the phone number with the formatted version
      phoneNumber = formatted;
    }

    function formatCount(){
      let cleaned = count.replace(/\D/g, '');
      if(parseInt(cleaned) < 1){
        cleaned = "1";
      }
      count = cleaned;
    }

    function OpenDatePicker(){
      const dateInput = document.getElementById('date');
      dateInput.removeAttribute('readonly');
      console.log(dateInput.getAttribute('readonly'));
      dateInput.showPicker();
      dateInput.setAttribute('readonly', true);
      dateInput.focus();
      console.log(dateInput.getAttribute('readonly'));
    }

</script>


<form id="{id}">
    {#each inputs as item}
    <div class="mb-6">
      <label for="{item.id}" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{item.label}</label>
      {#if item.type == "tel"}
        <input on:input={() => {phoneNumber = query(item.id).value; formatPhoneNumber(); query(item.id).value = phoneNumber;}} on:blur={onlosefocus(query(item.id))} type="{item.type}" id="{item.id}" class="bg-silver text-gray-900 text-sm rounded-lg focus:focusring block w-full p-2.5 " default="{item.default}" placeholder="{item.placeholder}" required>
      {:else if item.type == "date"}
      <div class="datecontainer">
        <input on:blur={onlosefocus(query(item.id))} type="{item.type}" id="{item.id}" class="dateinput bg-silver text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " default="{item.default}" placeholder="{item.placeholder}" required readonly>
        <button type="button" class="custom-date-picker-button" on:click={OpenDatePicker}>Click To Choose a Date</button>
      </div>
      {:else if item.type == "number"}
        <input value="1" on:input={() => {count = query(item.id).value; formatCount(); query(item.id).value = count;}} on:blur={onlosefocus(query(item.id))} on:input={onlosefocus(query(item.id))} type="{item.type}" id="{item.id}" class="bg-silver text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " default="{item.default}" placeholder="{item.placeholder}" required>
      {:else}
        <input on:blur={onlosefocus(query(item.id))} type="{item.type}" id="{item.id}" class="bg-silver text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " default="{item.default}" placeholder="{item.placeholder}" required>
      {/if}
    </div>
    {/each}
    
    {#if HasSubmit}
      <button type="submit" class="submitbutton text-white font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center ">Submit</button>
    {/if}
  </form>

  <style>
    .datecontainer button{
      position: absolute;
      right: 0;
      background: linear-gradient(to bottom, #888888, #444444);
      padding: 0.5rem;
      color: #dddddd;
      text-shadow: 0.05rem 0.05rem 0.05rem #222222;
      border-radius: 0.5rem;
      
    }
    input:focus, button:focus{
      outline: solid 0.1rem silver;
    }

    form button:hover{
      background: linear-gradient(to bottom, #444444, #666666);;
    }
    .datecontainer input{
      position: absolute;
    }
    .datecontainer{
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .submitbutton{
      background: #490000;

    }
    .submitbutton:hover{
      background: #800000;
    }
  </style>
  
<script>
    import CalenderBlock from "./CalenderBlock.svelte";
    
    export let monthrange;
    export let selecteddayofmonth = 1;
    export let SetDayOfMonth = () => {};
    export let daysthathavebookings = [];
    export let ChangeMonth = () => {};
    export let month = 0;
    export let year = 2023;
    let gridx = 7;
    let gridy = 7;

   
    
    let months = ["january","february","march","april","may","june","july","august","september","october","november","december"];

    

    function getdayofmonth(monthrange,x,y)
    {
        if(y > 0){
            if(y == 1){
                if(x < monthrange[0]){
                    return -1;
                }else{
                    return x - monthrange[0] + 1;
                }
            }else{
                let mx = (((y - 1) * 7) + x) - monthrange[0] + 1;
                let dm = mx;
                if(dm > monthrange[1]){
                    return -1;
                }else{
                    return dm;
                }
               
            }
        }
    }

    function gethasbooking(day){
        for(let i = 0; i < daysthathavebookings.length;i++){
            if(daysthathavebookings[i] == day){
                return true;
            }
        }
        return false;
    }

</script>

<div class="main">
    <div class="monthinfo">
        <button class="changemonth" on:click={() => ChangeMonth(-1)}>&#8592</button>
        <h3 class="month">{months[month]}, {year}</h3>
        <button class="changemonth" on:click={() => ChangeMonth(1)}>&#8594</button>
    </div>
    <div class="grid">
        
        {#each Array(gridy) as _,y}
            {#each Array(gridx) as _,x}
                {#if y == 0}
                    <CalenderBlock row={y+1} col={x+1} dayofweek={x} isheader={true}/>
                {:else}
                    <CalenderBlock Press={SetDayOfMonth} hasbooking={gethasbooking(getdayofmonth(monthrange,x,y))} row={y+1} col={x+1} dayofweek={x} dayofmonth={getdayofmonth(monthrange,x,y)} selected={getdayofmonth(monthrange,x,y) === selecteddayofmonth ? true : false} isheader={false}/>
                {/if}
            {/each}
        {/each}

    </div>
</div>

<style>
    .grid{
        justify-content: center;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(7,2rem);
        grid-template-rows: repeat(6,2rem);
    }
    .month{
        color:white;
    }
    .main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: sticky;
        top: 0;
    }
    .changemonth{
        all: unset;
        border-radius: 5px;
        text-align: center;
        margin-top: 0.15rem;
        font-size: large;
        width: 3rem;
        height: 3rem;
        color:white;
        cursor: pointer;
    }
    .changemonth:hover{
        all: unset;
        border-radius: 5px;
        text-align: center;
        margin-top: 0.15rem;
        font-size: large;
        width: 3rem;
        height: 3rem;
        color:white;
        background: #868686;
        cursor: pointer;
    }
    .monthinfo{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
</style>
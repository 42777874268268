<script>
    export let row = "1";
    export let col = "1";
    export let text = "";
    export let dayofweek = 1;
    export let dayofmonth = 1;
    export let selected = false;
    export let isheader = false;
    export let hasbooking = false;
    export let Press = () => {};
    const daysofweek = ["monday","tuesday","wednesday","thursday","friday","saturday","sunday"]

</script>


<button on:click={() => Press(dayofmonth,dayofweek)} class="cblock {hasbooking === true ? "hasbooking" : ""} {selected === true ? "blockselected" : ""} {isheader === true ? "blockheader" : ''}" style="--row:{row}; --col:{col};" disabled={isheader === true || dayofmonth === -1 ? true : false}>
    {#if isheader}
        {text = daysofweek[dayofweek].charAt(0)}
    {:else}
        {text = dayofmonth === -1 ? '' : dayofmonth.toString()}
    {/if}
    

</button>


<style>
    .cblock{
        all:unset;
        text-transform: uppercase;
        color:white;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        background: none;
        grid-row: var(--row), span(1);
        grid-column: var(--row), span(1);
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        
    }
    .blockselected{
        background: #888888;
        border-radius: 100%;
    }
    .hasbooking{
        position: relative;
    }
    .hasbooking::after{
        position: absolute;
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        transform: translate(-25%,-13%);
        background: #22ff22;
        border-radius: 100%;
        z-index: 5;
    }
    .blockheader{
        background: #565656;
        border-radius: 0.2rem;
    }

</style>
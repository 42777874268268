<script>
    import IconPerson from "./icons/IconPerson.svelte";
    import IconEmail from "./icons/IconEmail.svelte";
    import IconPhone from "./icons/IconPhone.svelte";
    import IconGroup from "./icons/IconGroup.svelte";
    import IconClock from "./icons/IconClock.svelte";
    import IconDesc from "./icons/IconDesc.svelte";
    import IconTable from "./icons/IconTable.svelte";

    export let booking;
    export let gridstart = 2;
    export let gridspan = 3;
    export let column = 1;
    export let server = "http://127.0.0.1:8000";

    function formatTime(timeString) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    }

    const rec = {
                    "rnone": "Once",
                    "rweekly": "Weekly",
                    "rbiweekly": "Biweekly"
    }
    
    function Edit(id){
        window.location = server + "/admin/tablebooking/booking/" + id.toString() + "/change/"
    }
    
</script>

<li class="booking" on:keydown={Edit(booking.id)} on:click={Edit(booking.id)} style="--gridstart:{gridstart};--gridend:{gridspan}; --col:{column}; --color:{booking.color};">
    {#if booking}
        <!--<p>{booking.start}</p>
        <p>{booking.end}</p>-->
        <div class="band"></div>
        <p><IconPerson style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.name}</p>
         <!--<p>{booking.user}</p>-->
        <p><IconEmail style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.email}</p>
        <p><IconPhone style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.phone}</p>
        <p><IconGroup style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.count}</p>
        <p class="showonhover"><IconTable  style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> Table {booking.table}</p>
        <p class="showonhover"><IconClock  style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {formatTime(booking.start)} To {formatTime(booking.end)} - {rec[booking.recurring]}</p>
        
        <p class="showonhover"><IconDesc  style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.description}</p>
    {/if}


</li>

<style>
    .booking{
        all:unset;
        grid-row-start: var(--gridstart);
        grid-row-end:span var(--gridend);
        grid-column-start: var(--col);
        grid-column-end: var(--col);
        background: #000000;
        
        color: white;
        border-radius: 5px;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        padding: none;
        padding-top: 0.5rem;
        overflow: hidden;
        filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25));

        transition: all 200ms ease-in-out;
        border: 1px var(--color) solid;
    }
    .band{
        background:var(--color);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        height: 0.5rem;
        z-index: 0;
        
    }
    .booking p{
        display: flex;
        flex-direction: row;
        padding-left: 0.5rem;
        margin: 0;
        height: 2.5rem;
        line-height: 2.5rem;
        vertical-align: middle;
        align-items: center;
        text-shadow: 0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25);
        font-size: small;

    }
    .booking .showonhover{
        visibility: hidden;
    }
    .booking:hover{
        height: 20rem;
        width: 20rem;
        transform: scale(1.4);
        z-index: 10;
    }
    .booking:hover .showonhover{
        visibility: visible;
    }
</style>
<script>

    import IconPerson from "./icons/IconPerson.svelte";
    import IconEmail from "./icons/IconEmail.svelte";
    import IconPhone from "./icons/IconPhone.svelte";
    import IconGroup from "./icons/IconGroup.svelte";
    import IconClock from "./icons/IconClock.svelte";
    import IconDesc from "./icons/IconDesc.svelte";
    import IconTable from "./icons/IconTable.svelte";
    import { onMount } from "svelte";
    import { query_selector_all } from "svelte/internal";

    let loading = false;
    let message = "Approval";
    export let OpenFunction = () => {};
    export let ApprovalFunction = () => {};
    export let bookings;
    export let Tables;

    let bookingstates = [];
  
    
    function RejectBooking(bookingname, id){
        
        message = "Rejection"
        loading = ApprovalFunction(bookingname, id, "rejected", -1)
        //setTimeout(() => {loading = false;}, 1000)
    }
    function formatTime(timeString) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
    }
    let months = ["january","february","march","april","may","june","july","august","september","october","november","december"];
    const rec = {
                    "rnone": "Once",
                    "rweekly": "Weekly",
                    "rbiweekly": "Biweekly"
    }
    function query(id){
      let el = document.querySelector("#" + id);
      return el;
    }
    function InitBookingState(){
        bookingstates = [];
        if(bookings != null){
            if(bookings.length > 0){
                for(let i = 0;i < bookings.length;i++){
                    bookingstates.push({'index': i, "table" : -1});
                }
            }
        }
        return true;
    }
    function GetTableNotAssigned(index){
        if(bookingstates.length <= 0){
            InitBookingState();
        }
        console.log(bookingstates[index].table < 0);
        return bookingstates[index].table < 0;
    }
    function AssignTable(index, table){
        if(bookingstates.length <= 0){
            InitBookingState();
        }
        bookingstates[index].table = table;
        if(table >= 0){
           query("accept" + index.toString()).disabled = false;
        }else{
            query("accept" + index.toString()).disabled = true;
        }
        console.log(index,table);
    }
    function ApproveBooking(bookingname, id,stateindex){
        message = "Approval"
        //setTimeout(() => {loading = false;}, 1000)
        loading = ApprovalFunction(bookingname, id, "approved", bookingstates[stateindex].table)
    }
</script>

<main>
    <div class="panel">
        <button class="closemodal" on:keydown={() => OpenFunction()} on:click={() => OpenFunction()}>X</button>
        <h4>Bookings Pending Approval</h4>
        <div class="bookingparent"> 
            {#await loading}
                <p class="loadingtext">Processing {message}</p>
            {:then}
                {#if bookings.length > 0}
                    <ul>
                    
                        {#each bookings as booking, i}
                        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
                        <li tabindex="0" class="book expanded"> {booking.name} - {months[booking.month - 1]} {booking.monthday}, {booking.year} - {rec[booking.recurring]}
                            <label for="table">Assign Table #</label>
                            <select name="table" on:change={(event) => AssignTable(i,parseInt(event.target.value))}>
                                <option value=-1>none</option>
                                {#each {length: Tables} as _, t}
                                    <option value={t + 1}>Table {t + 1}</option>
                                {/each}
                            </select>
                            <button id="accept{i}" disabled=true on:click={ApproveBooking((booking.name + " - " + months[booking.month - 1] + " " + booking.monthday.toString() + ", " + booking.year.toString()), booking.id, i)} class="accept">Accept</button>
                            <button on:click={RejectBooking((booking.name + " - " + months[booking.month - 1] + " " + booking.monthday.toString() + ", " + booking.year.toString()), booking.id,i)} class="reject">Reject</button>
                            <p class="flex flex-row py-10"><IconPerson style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.name}</p>
                            <!--<p>{booking.user}</p>-->
                            <p class="flex flex-row py-10"><IconEmail style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.email}</p>
                            <p class="flex flex-row py-10"><IconPhone style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.phone}</p>
                            <p class="flex flex-row py-10"><IconGroup style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.count}</p>
                            <p class="flex flex-row py-10"><IconClock  style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {formatTime(booking.start)} To {formatTime(booking.end)} - {rec[booking.recurring]}</p>
            
                            <p class="flex flex-row"><IconDesc  style={"width:1.5rem; height:1.5rem; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/> {booking.description}</p>
                        </li>
                        {/each}
                    </ul>
                    {:else}
                        <h4>No Bookings Pending</h4>
                    {/if}
                    
            {/await}
        </div>
        
    </div>

</main>

<style>

    main{
        pointer-events: none;
        background: rgba(0,0,0,0.0);
        position: fixed;
        z-index: 20;
        left: 0;
        right: 0;
        top:0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    main h4{
        all: unset;
        grid-row: 1;
        grid-column: 2;
        text-align: center;
        vertical-align: middle;

    }
    .panel{
        pointer-events: visible;
        position:absolute;
        right:0;
        top:0;
        bottom: 0;
        width: 40rem;
        padding: 1rem;
        display: grid;
        grid: 2rem auto / 2rem auto 2rem;
        background: #444444;
        color:white;
        border-radius: 5px;
        filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));    }

    .panel.closed{
        transform: translateX(43rem);
    }
    
    .closemodal{
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        background: none;
        border: none;
        color: white;
        border-radius: 1rem;
        grid-row: 1 span(1);
        grid-column: 1 span(1);
    }

    .bookingparent{
        grid-row: 2;
        grid-column: 2;
        
        overflow: auto;
    }
    .bookingparent ul{
         all: unset;
         padding: 1rem;
         
    }
    .book{
        
        all:unset;
        position: relative;
        display: block;
        background: #002200;
        border: green 1px solid;
        padding: 1rem;
        border-radius: 0.5rem;
        text-transform: capitalize;
        overflow: hidden;
        margin-bottom: 0.5rem;
        max-height:2rem;
        transition: max-height 300ms;
    }
    .book button{
        visibility: hidden;
        color: white;
        font:bold;
        background: #444444;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
    }
    .book select, label{
        visibility: hidden;
    }
    .book button:hover{
        
        
        filter: brightness(150%);
        box-shadow: 3px 3px #000;
    }
    .book .accept:hover{
        background: green;
    }
    .book .reject:hover{
        background: red;
    }
    .book button:disabled, .book button:hover:disabled{
        background: #000;
    }
    .book p{
        display: flex;
        padding-top: 1rem;
        visibility: hidden;
    }
    
    .book:focus-within .accept{
       
        position: absolute;
        right: 0.5rem;
        bottom: 0.5rem;
        padding:0.25rem;
    }
    .book:focus-within select{
        visibility: visible;
        position: absolute;
        right: 0.5rem;
        bottom: 3rem;
        width: 8rem;
        padding: 0.1rem;
    }

    .book:focus-within label{
        visibility: visible;
        position: absolute;
        right: 0.5rem;
        bottom: 5rem;
        width: 8rem;
    }

    .book:focus-within .reject{
        position: absolute;
        right: 5rem;
        bottom: 0.5rem;
        padding:0.25rem;
    }

    .book:focus-within{
       max-height: 100rem;
    }
    .book:focus-within button, .book:focus-within p{
        visibility: visible;
    }
    .book:focus-within .showonhover, .book:focus-within p{
        visibility: visible;
    }
    .showonhover{
        visibility: hidden;
    }
    .loadingtext{
        font-weight: bolder;
        text-transform: uppercase;
        text-align: center;
    }
    
</style>
<script>
    import { DateInput } from 'date-picker-svelte';
    import Form from '../Form.svelte';
    import TableTimePicker from './TableTimePicker.svelte';
    import InfoModal from '../InfoModal.svelte';
    
    export let timechoices = false;
    export let getdaybookings = () => {};
    export let RequestFunction = () => {};
    let datechosen = false;
    let date = new Date();
    let postingstate = "form";
    let postingmessage = "";
    let RequestPayload = {
        "name" : "",
        "email" : "",
        "phone" : "",
        "count" : "",
        "year" : "",
        "day" : "",
        "month" : "",
        "start" : "",
        "end" : "",
        "desc" : "",

    }

    async function WaitForDateSelection(dateelement){
        if(dateelement.id == "date"){
            getdaybookings(date);
            datechosen = true;
        }
        console.log(dateelement.id);
    }
    function ReloadPage(){
        window.location.reload();
    }
    function ShowForm(){
        postingstate = "form";
    }

    function IsDateInputValid(datevalue){
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        console.log("datevalue = " + datevalue);
        return regex.test(datevalue);
    }

    function SetPayload(element){
        switch(element.id){
            case "date":
                if(IsDateInputValid(element.value) == true)
                {
                    date = new Date(element.value);
                    RequestPayload.year = date.getFullYear();
                    RequestPayload.month = date.getUTCMonth() + 1;
                    RequestPayload.day = date.getUTCDate();
                    WaitForDateSelection(element);
                    break;
                }
                break;
            case "name":
                RequestPayload.name = element.value;
                break;
            case "email":
                RequestPayload.email = element.value;
                break;
            case "phone":
                RequestPayload.phone = element.value;
                break;
            case "participants":
                RequestPayload.count = element.valueAsNumber;
                console.log("countinput = " + element.valueAsNumber);
                console.log("count = " + RequestPayload.count);
                break;
            case "starttime":
                RequestPayload.start = element.value;
                RequestPayload.start = RequestPayload.start.replaceAll('-', ':');
                break;
            case "endtime":
                RequestPayload.end = element.value;
                RequestPayload.end = RequestPayload.end.replaceAll('-', ':');
                break;
            case "desc":
                RequestPayload.desc = element.value;  
                break;

        }
        console.log("PAYLOAD = " + JSON.stringify(RequestPayload));
    }

   async function SubmitRequest(){
        if(FormDataValid(RequestPayload)){
            postingstate = "posting";
            const res = await RequestFunction(RequestPayload);
            postingmessage = res.message;
            postingstate = res.result;
            
        }
    }
    function query(id){
      let el = document.querySelector("#" + id);
      return el;
    }
    function FormDataValid(payload){
        var form = query("requestform");
        if(form.checkValidity() == false){
            var list = form.querySelectorAll(':invalid');
            for(var item of list){
                item.focus();
                alert("Please fill out all of the questions on the form.");
                return false;
            }
        }
        return true;
    }
</script>

<main>
    <div class="formframe bg-silver p-4 rounded-md w-[40rem]" style="pointer-events: all;">
        {#if postingstate == "form"}
            <Form id="requestform" onlosefocus={SetPayload}  HasSubmit={false} inputs=
                {
                    [
                        {
                            "type": "name",
                            "label" : "Your Name",
                            "id" : "name",
                            "placeholder" : "John Smith"
                        },
                        {
                            "type": "email",
                            "label" : "Your email",
                            "id" : "email",
                            "placeholder" : "John@email.com"
                        },
                        {
                            "type": "tel",
                            "label" : "Your Phone Number",
                            "id" : "phone",
                            "placeholder" : "5555555555"
                        },
                        {
                            "type": "number",
                            "label" : "Number of players",
                            "id" : "participants",
                            "placeholder" : "Number of Players",
                            "default" : "1"
                        },
                        {
                            "type": "date",
                            "label" : "Date",
                            "id" : "date",
                            "placeholder" : "1"
                        },
                        {
                            "type": "text",
                            "label" : "Description",
                            "id" : "desc",
                            "placeholder" : "A short description."
                        },
                    ]
                }
            />
            {#if timechoices}
                <p class="text-white mb-2">* Available time choices are limited to table availability.</p>
                <TableTimePicker oninputchange={SetPayload} starttimes={timechoices} />
        
                <button on:click={SubmitRequest} type="button" class="submitbutton text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Submit</button>
            {/if}
        {/if}

        <!-- a conditional to display the result of the post request -->
        {#if postingstate === "posting"}
            <p class="text-white">Requesting booking...</p>
        {:else if postingstate === "success"}
            <InfoModal message="Booking request submitted! You will receive an email confirming whether your request has been accepted or not." togglevisible={ReloadPage} />
        {:else if postingstate === "notavailable"}
            <InfoModal message="The time you have chosen is no longer available. Please choose another time." togglevisible={ShowForm} />
        {:else if postingstate === "error"}
            <InfoModal message="Something went wrong. Please try again later or contact support. {postingmessage}" togglevisible={ReloadPage} />
           
        {/if}
    </div>

   <!-- <div class="text-red-500">
        <label for="firstname" style="grid-column: 1 1; grid-row: 1 1;">First Name</label>
        <input type="text" name="firstname" style="grid-column: 1 1;grid-row: 1 1;"/>

        <label for="lastname" style="grid-column: 2 2; grid-row: 1 1;">Last Name</label>
        <input type="text" name="lastname" style="grid-column: 2 2;grid-row: 1 1;">

        <label for="email" style="grid-column: 1 1; grid-row: 2 2;">Email</label>
        <input type="email" name="email" style="grid-column: 1 1;grid-row: 2 2;">

        <label for="phone" style="grid-column: 2 2; grid-row: 2 2;">Phone Number</label>
        <input type="tel" name="phone" style="grid-column: 2 2;grid-row: 2 2;">

        <label style="grid-column: 2 2; grid-row: 3 3;">Date</label>
        <DateInput bind:value={date} format={"yyyy-MM-dd"} max={new Date("2099")}/>
    </div>-->
</main>

<style>
    main{
        display: flex;
        justify-content: center;
        align-items: center;
        
        left:0;
        right:0;
        top:0;
        bottom:0;
        pointer-events: none;
        
    }
    .adiv{
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto;
        background: #444444;
        padding: 2.5rem;
        border-radius: 1rem;
    }
    .formframe{
        margin-bottom: 2rem;
    }
    label{
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .invalid{
        border: red solid 0.5rem;
    }
    @media (max-width: 800px){
        .submitbutton{
            width: 100%;
        }
    }

    .submitbutton{
        background-color: #490000;
        
    }
    .submitbutton:hover{
        background-color: #800000;
    }

    input:focus, button:focus, select:focus{
      outline: solid 0.1rem silver;
    }
    
</style>
<script>
   
</script>

<div class="main">

    <h1>Industrial Park Games <span>Ltd.</span> Table Booking</h1>
    <a href="/accounts/logout/">Logout</a>

</div>

<style>
span{
    font-size: small;
}
h1{
    vertical-align: middle;
}
.main{
    width: 100%;
    left:0;
    right:0;
    top:0;
    
    border-bottom: solid 1px #444444;
    
    color:white;

    display: flex;
    justify-content: space-between;

    padding: 2rem;
    margin-bottom: 3.5rem;
}
a{
    color: white;
    font-weight: bold;
    
}
</style>
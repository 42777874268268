<script>
    export let visible = true;
    
    export let togglevisible = () => {};
    export let PostBooking = () => {};
  
    let year;
	let month;
	let day;
	let starttime;
	let endtime;
	let table;
	let name;
	let email;
	let phone;
	let count;
	let desc;
</script>

{#if visible == true}
    <div class="bmodal">
        <div class="bmodalbg">
            <button class="closemodal" on:click={() => togglevisible()}>&#9932</button>
            <h2>request a booking</h2>
            <div class="form">
                <div>
                    <label for="name">name</label>
                    <input bind:value={name} name="name" type="text"/>
                </div>
                <div>
                    <label for="email">email</label>
                    <input bind:value={email} name="email" type="text"/>
                </div>
                <div>
                    <label for="phone">phone number</label>
                    <input bind:value={phone} name="phone" type="tel"/>
                </div>
                <div>
                    <label for="table">table number</label>
                    <input bind:value={table} name="table" type="number"/>
                </div>
                <div>
                    <label for="day">day</label>
                    <input bind:value={day} name="day" type="number"/>
                </div>
                <div>
                    <label for="month">month</label>
                    <input bind:value={month} name="month" type="number"/>
                </div>
                <div>
                    <label for="year">year</label>
                    <input bind:value={year} name="year" type="number"/>
                </div>
                <div>
                    <label for="start">start time</label>
                    <input bind:value={starttime} name="start" type="time"/>
                </div>
                <div>
                    <label for="end">end time</label>
                    <input bind:value={endtime} name="end" type="time"/>
                </div>
                <div>
                    <label for="playercount">player count</label>
                    <input bind:value={count} name="playercount" type="number"/>
                </div>
                <div>
                    <label for="description">short description</label>
                    <input bind:value={desc} name="description" type="text"/>
                </div>
                <button on:click={() => [PostBooking(year,month,day,starttime,endtime,table,name,email,phone,count,desc),togglevisible()]}>submit</button>
            </div>
        </div>

    </div>
{/if}

<style>
    .bmodal{
        z-index: 10;
        position: fixed;
        display: flex;
        justify-content: center;
        backdrop-filter: blur(5px);
        background: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
       
    }
    .bmodalbg{
        position: fixed;
        top: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        width: 800px;
        bottom: 2rem;
        background: #444444;
        border-radius: 1rem;
        
        display: grid;
        grid-template-columns: 2rem 95%;
        grid-template-rows: 5rem auto;

        justify-items: center;
    }
    .bmodalbg h2{
        color: white;
        text-transform: capitalize;
        grid-row: 1 span(1);
        grid-column: 2 span(1);
    }
    .bmodalbg .form{
        
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        justify-content: center;
        grid-row: 2;
        grid-column: 2;
        width: 30rem;
    }
    .bmodalbg .form div{
        margin: none;
    }
    .bmodalbg .form button{
        background: #490000;
        border: none;
        color:white;
        text-transform: uppercase;
        font-weight: bold;
        border-radius: 0.2rem;
        width: 100%;
        height: 3rem;
    }
    input, label{
        margin: none;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding: none;
        width: 8rem;
        height: 2rem;
        color:white;
        
    }
    label{
        text-transform: capitalize;
    }
    input{
        background-color: #333333;
        border: none;
    }
    .closemodal{
        
        width: 2rem;
        height: 2rem;
        background: none;
        border: none;
        color: white;
        border-radius: 1rem;
        grid-row: 1 span(1);
        grid-column: 1 span(1);
    }

</style>
<script>
	import { onMount } from "svelte";
	import BookingList from "./components/BookingList.svelte";
	import Calendar from "./components/Calendar.svelte";
	import Tabs from "./components/Tabs.svelte";
	import CreateBooking from "./components/CreateBooking.svelte";
	import InfoModal from "./components/InfoModal.svelte";
	import TableColumn from "./components/TableColumns.svelte";
	import ApprovalWindow from "./components/ApprovalWindow.svelte";
	import IconSpinner from "./components/icons/IconSpinner.svelte";
    import Customerheader from "./components/customer/customerheader.svelte";
    import Requestabooking from "./components/customer/requestabooking.svelte";
	import "../static/tailwind.css";
	
	const BetaDisclaimer = "This booking app is still under development. Certain features may not work as expected and you may experience strange behaviour. Please note that any information entered into this app will be viewed by IPG staff and developers. This info will NOT be used for any purpose other than in regard to booking tables. By continuing, you acknowledge this. If you have any questions or concerns, please contact us at info@industrialparkgames.com";
	let bookings = [];
	let notapprovedbookings = [];
	let timeslots = [];
	let monthrange = [];
	let hours = [];
	let is_guest = false;
	let is_staff = false;
	const currentdate = new Date();
	let tables = 1;
	let selecteddayofmonth = currentdate.getDate();
	let dayofweek = currentdate.getDay();
	let selecteddayopenningtime;
	let month = currentdate.getMonth() + 1;
	let year = currentdate.getFullYear();

	//ReCAPTCHA
	//wraps enterprise ready into a promise so that it can be awaited
	let ReCAPTCHALibraryLoaded_promise = new Promise(function(myResolve, myReject) {
		grecaptcha.enterprise.ready(() => {myResolve(); console.log("ReCAPTCHA Library promise Loaded")});
	});


	let server = "https://tablebooking.industrialparkgames.com";
	//let server = "http://127.0.0.1:8000";
	let activetable = 1;

	let load = getbookingsformonth(year,month);
	let approvalwindowopen = false;
	let reqdaybookchoices = null;

	
	function ToggleApprovalWindow(){
		if(approvalwindowopen == false){
			getnotapprovedbookings();
		}
		approvalwindowopen = !approvalwindowopen;
	}

	function RefreshApprovalWindow(){
		getnotapprovedbookings();
	}
	function RefreshBookings(){
		load = getbookingsformonth(year,month);
	}

	async function getbookingsformonth(year,month){
		let url = server + "/tablebooking/getbookings/";
		if(year != null && month != null){
			url = server + "/tablebooking/getbookings/" + "?year=" + year.toString() + "&month=" + month.toString();
		}
		try{
			
			let res = await fetch(url);
			let data = await res.json();
			console.log(data);
			bookings = data.bookings;
			timeslots = data.timeslots;
			hours = data.hours;
			monthrange = data.monthrange;
			tables = data.tables;
			is_guest = data.is_guest;
			is_staff = data.is_staff;
		}
		catch(error){
			console.log(error);
			ErrorForceLogout();
			return [];
		}
	}

	async function getbookingsforday(year,month,day){
		console.log("get bookings for day");
		let url = server + "/tablebooking/getavailability/";
		if(year != null && month != null && day != null){
			url = server + "/tablebooking/getavailability/" + "?year=" + year.toString() + "&month=" + month.toString() + "&day=" + day.toString();
		}
			await fetch(url)
				.then(response => response.json())
				.then(data => {
					console.log(data);
					
					timeslots = data.timeslots;
					reqdaybookchoices = data.timeslots;
					console.log("choices: " + reqdaybookchoices.toString());
					hours = data.hours;
					monthrange = data.monthrange;
					tables = data.tables;
					is_guest = data.is_guest;
					is_staff = data.is_staff;
					}).catch(error => {
						console.log(error);
						ErrorForceLogout();
						return [];
			});
	}

	async function getnotapprovedbookings(){
		let url = server + "/tablebooking/getbookings/";
		url = server + "/tablebooking/getbookings/" + "?notapproved=True";
		await fetch(url)
			.then(response => response.json())
			.then(data => {
				console.log(data);
				notapprovedbookings = data.bookings;
				timeslots = data.timeslots;
				hours = data.hours;
				monthrange = data.monthrange;
				tables = data.tables;
				}).catch(error => {
					console.log(error);
					ErrorForceLogout();
					return [];
		});
	}

	async function SetBookingApproval(bookingname,bookingid, approval, table){
		let url =  server + "/tablebooking/approve/" 
		const res = await fetch(url, {
			method: 'POST',
			headers: {"X-CSRFToken": getCookie("csrftoken")},
			body: JSON.stringify({
				"bookingname": bookingname,
				"id": bookingid,
				"table" : table,
				"approval": approval,		
			})
		}).catch(error => {
			console.log(error);
			ErrorForceLogout();
			return [];
		});
		const json = await res.json();
		RefreshBookings();
		RefreshApprovalWindow();
		ShowInfoModalMessage(json.message);
		console.log(json);
	}

	
	async function PostBooking(payload){
		let url = server + "/tablebooking/createbooking/book/" 
		let retoken = await ReCAPTCHA_TokenBook();
		console.log("retoken = " + retoken);
		try{
			let postres = await fetch(url, {
					method: 'POST',
					headers: {"X-CSRFToken": getCookie("csrftoken")},
					body: JSON.stringify({
						"year": payload.year,
						"month": payload.month,
						"day": payload.day,
						"start": payload.start,
						"end": payload.end,
						"table": payload.table,
						"name": payload.name,
						"email": payload.email,
						"phone": payload.phone,
						"count": payload.count,
						"description": payload.desc,
						"retoken": retoken,
						"expectedAction": "book",
						"KEY_ID": '6LeGLYgoAAAAAMdYP6OJKOXQrk1Jn8xShJcN6Y_M',
					})
			});
			let json = await postres.json();
			ShowInfoModalMessage(json.result);
			console.log(json);
			return json;
		} 
		catch(error) {
			console.log(error);
			ErrorForceLogout();
			return [];
		}
	}
	async function ReCAPTCHA_TokenBook() {
		try {
			return await grecaptcha.enterprise.execute('6LeGLYgoAAAAAMdYP6OJKOXQrk1Jn8xShJcN6Y_M', {action: 'book'});
		} catch (error) {
  			console.error(error);
		}
	}
	async function ReCAPTCHA_TokenPageLoad(){
		try {
			return await grecaptcha.enterprise.execute('6LeGLYgoAAAAAMdYP6OJKOXQrk1Jn8xShJcN6Y_M', {action: 'load'});
		} catch (error) {
  			console.error(error);
		}
	}


	//TO GET THE CSRF TOKEN FROM COOKIES
	var getCookie = function(name) {
		var cookieValue = null;
		if (document.cookie && document.cookie !== '') {
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i].trim();
				if (cookie.substring(0, name.length + 1) == (name + '=')) {
					cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
					break;
				}
			}
		}
		return cookieValue;
	};
	
	
	

	function SetActiveTable(t){
		activetable = t;
	};

	function SetDayOfMonth(day,dw){
		selecteddayofmonth = day;
		dayofweek = dw;
		selecteddayopenningtime = hours.open[dw];
		ScrollDown(formatTime(selecteddayopenningtime));
		console.log("weekday " + dw);
	}


	function GetDaysThatHaveBookings(){
		let ba = [];
		for(let i = 0;i < bookings.length;i++){
			ba.push(bookings[i].monthday);
		}
		return ba;
	}
	
	let bookingmodalvisible = false;
	function togglebookingmodalvisible(){
        bookingmodalvisible = !bookingmodalvisible;
    }
	let infomodalvisible = false;
	let infomodalmessage = "";
	function toggleinfomodalvisible(){
        infomodalvisible = !infomodalvisible;
    }
	function ShowInfoModalMessage(message){
		infomodalmessage = message;
		infomodalvisible = true;
	}
	
	//changes the month and advances the year if needed, or goes back a year if needed
	function ChangeMonth(dir) {
		// Calculate the new month and year based on the direction
		const newMonth = month + dir;
		const newYear = year + Math.floor((newMonth - 1) / 12);

		// Update the month and year
		month = ((newMonth - 1) % 12) + 1;
		year = newYear;

		// Load the bookings for the new month and year
		load = getbookingsformonth(year, month);
	}


	//formats the time from 24 hour to 12 hour
	function formatTime(timeString) {
        const [hourString, minute] = timeString.split(":");
        const hour = +hourString % 24;
        return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
    }
	//scrolls the page to the element with the id passed in
	function ScrollDown(ID){
		window.location.hash = ID.toString();
	}
	//request bookings for a specific date
	function RequestBookingsForDate(date){
		console.log(date.getFullYear().toString());
		getbookingsforday(date.getFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
	}
	//function to force logout if something goes wrong
	function ErrorForceLogout(){
		alert("Something went wrong. Please log back in and try again.");
		window.location.replace("/accounts/logout/");
	}
	
</script>

<main>
	
	{#await load}
		<div class="loadingscreen">
			<div class="IconSpinner"><IconSpinner  style={"width:3rem; height:3rem;font-weight:bolder; margin-right:0.2rem;filter: drop-shadow(0.05rem 0.05rem 0.25rem rgb(0,0,0,0.25))"}/></div>
		</div>
	{:then}
		{#if is_staff == true}
		<!--IS A STAFF MEMBER-->
			{#if approvalwindowopen}
				<ApprovalWindow bookings={notapprovedbookings} OpenFunction={ToggleApprovalWindow} Tables={tables} ApprovalFunction={SetBookingApproval}/>
			{/if}
			<InfoModal togglevisible={toggleinfomodalvisible} visible={infomodalvisible} message={infomodalmessage}/>
			<CreateBooking visible={bookingmodalvisible} togglevisible={togglebookingmodalvisible} PostBooking={PostBooking}/>
			<div class="sidebar">
				<Calendar year={year} month={month - 1} ChangeMonth={ChangeMonth} SetDayOfMonth={SetDayOfMonth} daysthathavebookings={GetDaysThatHaveBookings()} selecteddayofmonth={selecteddayofmonth} monthrange={monthrange}/>
				<ul style="position:sticky;top:20rem">
					<li><a on:click={ToggleApprovalWindow} href="#">Approve Bookings</a></li>
					<li><a on:click={togglebookingmodalvisible} href="#">request a booking</a></li>
					<li><a href="/admin"> admin</a></li>
					<li><a href="/accounts/logout/"> Logout</a></li>
					<li><a href="mailto:info@industrialparkgames.com">Contact Us</a></li>
				</ul>

			</div>
			<div style="flex: 2 2;">
				<!--<Tabs tabamount={tables} SetTab={SetActiveTable}/>-->
				<TableColumn server={server} tableamount={tables} bookings={bookings} timeslots={timeslots} dayofweek={dayofweek} dayofmonth={selecteddayofmonth}></TableColumn>
				<!--{#each Array(tables) as _,i}
					<BookingList timeslots={timeslots} dayofweek={dayofweek} dayofmonth={selecteddayofmonth} bookings={bookings} table={i + 1} visible={activetable === i + 1 ? true : false}>
					</BookingList>
					
				{/each}-->
			</div>
		{:else}
			<!--IS NOT A STAFF MEMBER-->
			<!--DISCLAIMER--><InfoModal message={BetaDisclaimer} />
			<div class="customercontainer">
				<Customerheader/>
				<Requestabooking RequestFunction={PostBooking} getdaybookings={RequestBookingsForDate} bind:timechoices={reqdaybookchoices}/>
			</div>
		{/if}
	{/await}
		<!-- a button that closes the browser -->
	<div class="grad"></div>
</main>

<style>
	.customercontainer{
		display: flex;
		flex-direction: column;
		justify-items: center;
		width: 100%;
	}
	main{
		display: flex;
		flex-direction: row;
	}
	
	.sidebar{
		width: 16rem;
		background: #444444;
		margin-right: 1rem;
	}
	.sidebar a{
		text-decoration: none;
		color: white;
		font-weight: bold;
		text-transform: capitalize;
	}
	.grad{
		pointer-events: none;
		position: fixed;
		left: 0;
		right: 0;
		top:0;
		bottom: 0;
		background: radial-gradient(#565656,#222222);
		z-index: 100;
		opacity: 50%;
		mix-blend-mode: lighten;
	}
	.loadingscreen{
		display: flex;
		justify-content: center;
		align-items: center;
		background: #444444;
		position: absolute;
		z-index: 50;
		left: 0;
		right: 0;
		top:0;
		bottom: 0;
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
		color:white;

	}
	.loadingscreen.IconSpinner{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.IconSpinner{
		animation: rotate 3s linear infinite;
	}
	@keyframes rotate {
  		to {
    		transform: rotate(360deg);
		}
  	}
	
</style>